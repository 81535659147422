<template>
  <div class="main">
    <nyheadsndl />
    <div class="ny-nav">
      <router-link to="/">论文助手</router-link>&nbsp;&nbsp;>&nbsp;&nbsp;登录
    </div>
    <div class="ny-con">
      <div class="login-title">
        <div class="login-title-list">账号登录</div>
      </div>
      <div class="login-srk">
        <el-form 
        :label-position="'top'"
          label-width="200px"
          :model="form"
          :rules="baseRules"
          ref="ruleFormRef"
        >
          <el-form-item label="手机号" required prop="username">
            <el-input v-model="form.username"
              placeholder="请输入手机号"
              @keyup.enter="onSubmit(ruleFormRef)" />
          </el-form-item>
          <el-form-item label="密码" required prop="password">
            <el-input v-model="form.password"
              type="password"
              placeholder="请输入密码"
              @keyup.enter="onSubmit(ruleFormRef)" />
          </el-form-item>
          <div class="login-srk-wjmm">
            <div class="login-srk-wjmm-link"><router-link to="/czpsw">忘记密码</router-link></div>
            <div class="login-srk-wjmm-link"><router-link to="/registered">没有账号？立即注册</router-link></div>
          </div>
          <div class="login-srk-btns">
            <el-button type="primary" size="large" @click="onSubmit(ruleFormRef)" >登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <footers />
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

let form = reactive({
  username: "",
  password: "",
});
const ruleFormRef = ref();
const baseRules = {
  username: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
  password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
};

// 登录
const onSubmit = (formEl) => {
  // console.log(formEl);
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      post("/api/UserLogin/Login", form).then((res) => {
        // 保存token
        window.localStorage.setItem("token", res.data);
        // 获取用户信息
        get("/api/User/GetTheUserInfo").then((result) => {
          window.localStorage.setItem(
            "userInfo",
            JSON.stringify(result.data)
          );
          window.localStorage.setItem("username",result.data.username);
          ElMessage({
            message: "登录成功！",
            duration: 1000,
            onClose: () => {
              // 存在地址则跳回
              if (window.localStorage.getItem("loginurl")) {
                window.location.href = window.localStorage.getItem("loginurl");
              } else {
                router.push({
                  path: "/",
                  query: {},
                }); 
              }
            },
          });
        });
      });
    }
  });
};

</script>

<style scoped>
:deep(.el-form-item__label){
  font-weight: bold;
}
:deep(.el-form-item__content){
  margin-left: 0 !important;
}
:deep(.login-srk-btns .el-button){
  width: 100%;
}
:deep(.el-form){
  padding: 50px 0;
}
.main {
  background: #ebedf3;
}
.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.login-title {
  height: 50px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e3e3e3;
}
.login-title-list {
  padding: 0 20px;
  height: 48px;
  line-height: 48px;
  border-bottom: 2px solid #2489f2;
}
.login-srk {
  width: 500px;
  height: auto;
  margin: auto;
}
.login-srk-title{
  font-weight: bold;
  padding: 0 0 10px 0;
}
.login-srk-wjmm{
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding:10px 0  20px 0;
}
.login-srk-wjmm-link a:link,.login-srk-wjmm-link a:visited{
  color: #2489f2;
  text-decoration: none;
}
.login-srk-wjmm-link a:hover{
  text-decoration: underline;
}
</style>
